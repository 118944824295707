import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './ascension.scss';
import { Table } from 'react-bootstrap';
import { EversoulCharacter } from '../../../modules/es/common/components/eversoul-character';

const EsGuidesCampUnlockPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ascension-guide'} game="es">
      <ul className="breadcrumb">
        <li>
          <Link to="/eversoul/">Eversoul</Link>
        </li>
        <li className="divider">/</li>
        <li>Campaign Unlocks and Rewards</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/eversoul/categories/category_progress.png"
            alt="Campaign unlocks"
          />
        </div>
        <div className="page-details">
          <h1>Campaign unlocks</h1>
          <h2>
            List of features you will unlock by progressing through the
            campaign.
          </h2>
          <p>
            Last updated: <strong>21/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Campaign Unlocks" />
        <StaticImage
          src="../../../images/eversoul/generic/guide_campaign.webp"
          alt="Guide"
        />
        <p>
          As you progress thought the Campaign, you will continue to unlock new
          game modes and features in the game. While initially, the rate of the
          unlocks is pretty fast paced, it slows down later.
        </p>
        <p>
          We will continue to update the list as we progress though the
          Campaign.
        </p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Stage</th>
              <th>Unlock</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>2-1</td>
              <td>Quests</td>
            </tr>
            <tr>
              <td>2-3</td>
              <td>Shop</td>
            </tr>
            <tr>
              <td>2-5</td>
              <td>Friends</td>
            </tr>
            <tr>
              <td>2-8</td>
              <td>Friends</td>
            </tr>
            <tr>
              <td>2-10</td>
              <td>Zodiac Blessing</td>
            </tr>
            <tr>
              <td>2-15</td>
              <td>Adventure</td>
            </tr>
            <tr>
              <td>2-20</td>
              <td>Town / Evertalk</td>
            </tr>
            <tr>
              <td>2-25</td>
              <td>Level Sync</td>
            </tr>
            <tr>
              <td>2-28</td>
              <td>Guilds and Guild Raid</td>
            </tr>
            <tr>
              <td>3-10</td>
              <td>Dimensional Labyrinth mode</td>
            </tr>
            <tr>
              <td>3-10</td>
              <td>Arena</td>
            </tr>
            <tr>
              <td>3-20</td>
              <td>Hall of Memories mode</td>
            </tr>
            <tr>
              <td>3-30</td>
              <td>Decoy Operations (Mana Crystal)</td>
            </tr>
            <tr>
              <td>4-10</td>
              <td>Artifacts</td>
            </tr>
            <tr>
              <td>5-1</td>
              <td>Champions Arena</td>
            </tr>
            <tr>
              <td>5-10</td>
              <td>Evil Soul Subjugation</td>
            </tr>
            <tr>
              <td>8-16</td>
              <td>Operation Eden Alliance & Ark Enhancement</td>
            </tr>
            <tr>
              <td>8-35</td>
              <td>Tower of Origin</td>
            </tr>
            <tr>
              <td>9-15</td>
              <td>World Raid</td>
            </tr>
            <tr>
              <td>10-2</td>
              <td>Erika Alchemy</td>
            </tr>
            <tr>
              <td>11-40</td>
              <td>Iridescent Realm & Potential System</td>
            </tr>
            <tr>
              <td>19-50</td>
              <td>Decoy Operations (Mana Dust, Gold and Keepsake Stone)</td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="Campaign rewards" />
        <StaticImage
          src="../../../images/eversoul/generic/guide_campaign.webp"
          alt="Guide"
        />
        <p>
          For every Campaign chapter you clear, you will receive a reward - Most
          oftenly it is Tickets (Either Artifact, or Type Summon, or Normal
          Summon Tickets).
        </p>
        <Table striped bordered responsive className="banner-history">
          <thead>
            <tr>
              <th>Chapter</th>
              <th>Reward</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>2</td>
              <td>
                <EversoulCharacter mode="icon" slug="chloe" enablePopover />
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>
                <EversoulCharacter mode="icon" slug="clara" enablePopover />
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>
                <EversoulCharacter mode="icon" slug="rebecca" enablePopover />
              </td>
            </tr>
            <tr>
              <td>6</td>
              <td>
                <EversoulCharacter mode="icon" slug="linzy" enablePopover />
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default EsGuidesCampUnlockPage;

export const Head: React.FC = () => (
  <Seo
    title="Campaign unlocks | Eversoul | Prydwen Institute"
    description="List of features you will unlock by progressing through the campaign."
    game="eversoul"
  />
);
